.jumpBtnContainer {
  height: 45px;
  width: 110px;
  float: right;
}

.jumpBtn {
  border: 1px solid black;
  position: relative;
  font-size: 12px;
  z-index: 99;
  cursor: pointer;
  border-radius: 5px;
}

.jumpBtn:hover {
  background-color: #303F9F;
  color: white;
}