.table-bordered th, .table-bordered td {
  width: 30px;
  max-width: 160px;
  word-wrap: break-word;
}

.table-bordered .template-link {
  color: #0d47a1;
  text-decoration: underline transparent;
  transition: text-decoration-color 0.1s linear;
}
.table-bordered .template-link:hover {
  text-decoration: underline;
  text-decoration-color: #0d47a1;
}