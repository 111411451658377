.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: block;
}

.modal-main {
  position:fixed;
  background: white;
  width: 95%;
  height: 500px;
  top:50%;
  left:50%;
  display:flex;
  flex-direction: row;
  transform: translate(-50%,-50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
/* css */
html {
  scroll-behavior: smooth;
}


.modal-main .checkbox-btn, .modal-main .radio-btn {
  vertical-align: sub;

}
.modal-main .checkbox-label, .modal-main .radio-label {
  padding-left: 10px;
  /* margin: 0; */

}

.modal-main .lead-details {
  height: 100%;
  width: 50%;
  background-color:#fafafa;
}

.modal-main .lead-details-text {
  font-weight: 700
}

.modal-main .preview-section {
  height: 100%;
  width: 50%;
}

.modal-main .block-content {
  padding: 20px;
}

.modal-main .email-subject {
  font-weight: 700;
}
.modal-main .email-section {
  background-color: #fafafa;
  padding: 18px
}

.display-block {
  /* display: block; */
  opacity: 1;
  transition: opacity 0.1s ease-in;
  pointer-events: initial;
}

.display-none {
  opacity: 0;
  pointer-events: none;
  /* display: none; */
  transition: opacity 0.1s ease-out;
}